<template>
  <div class="faq_container container">
    <h1 class="head_title">Frequently Asked Questions</h1>
    <div v-for="(list , i) in collapseList" :key="i">
      <h2 class="head_title">{{ list.headTitle }}</h2>
      <b-card no-body v-for="(item , index) in list.content" :key="index">
        <b-card-header header-tag="header">
          <div v-b-toggle="'accordion-' + i + index">
            <div class="d-flex justify-content-between align-items-center">
              <b-link>{{ item.title }}</b-link>
              <div class="icon_rotate">
                <i class="icon icon-arrowleft"></i>
              </div>
            </div>
          </div>
        </b-card-header>
        <b-collapse :id="'accordion-' + i + index" visible accordion="my-accordion">
          <b-card-body>
            <b-card-text v-html="item.content"></b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        collapseList: [{
          headTitle: "General",
          content: [{
            title: "What is a DaVinciCard Visa® Prepaid Card?",
            content: `<p>The DaVinciCard Visa® Prepaid Card is a virtual card that can be loaded with USDT-TRC20.</p>
            <p>Crypto is converted to USD the moment it's loaded to the card and funds are immediately available to spend everywhere Visa cards are accepted online around the world. Restrictions Apply: http://paywithDaVinci.com/terms-conditions</p>`
          }, {
            title: "How do virtual cards work?",
            content: `<p>Virtual cards are just like regular cards, except there is no physical plastic. By entering virtual card details where you would normally input your credit or debit card details, you can complete purchases without giving out your personal card details.</p>`
          }, {
            title: "Where can I shop/pay with DaVinci Visa® Prepaid Cards?",
            content: `<p>DaVinci enables you to shop/pay with your crypto everywhere Visa cards are accepted online around the world. Some restrictions apply, please see the terms and conditions for details.</p>`
          }, {
            title: "How do I fund a DaVinci Visa® Prepaid Card?",
            content: `<p>You can fund a virtual card with your Binance account or other enabled wallet.</p>`
          }, {
            title: "Do I have to apply for a DaVinci Visa® Prepaid Card?",
            content: `<p>No, there is no application process to create or use a DaVinci Visa® Prepaid Card.</p>`
          }, {
            title: "Do I have to use a DaVinci Visa® Prepaid Cards to pay with DaVinci?",
            content: `<p>If you want to spend your crypto through DaVinci, you must create a DaVinci Visa® Prepaid Card and fund it with USDT-trc20.</p>`
          }, {
            title: "What billing name and address do I enter when paying with a DaVinci Visa Prepaid® card?",
            content: `<p>When paying with a DaVinci Visa® Prepaid Card, you should use the name associated with your DaVinci account. You can use any billing address you want.</p>`
          }, {
            title: "How do I delete a virtual card?",
            content: `<p>you don't need to delete cards</p>`
          }, {
            title: "How long is a DaVinci Visa® Prepaid Card valid?",
            content: `<p>DaVinci Visa® Prepaid Cards can be valid during 720 days of creation, even longer.</p>`
          }, {
            title: "Can I combine two cards or transfer a balance from one to another?",
            content: `<p>No</p>`
          }, {
            title: "Are there any fees?",
            content: `<p>DaVinci does charge fees for purchasing cards, having an account or making purchases with cards. DaVinci reserves the right to charge fees for certain activities to mitigate abusive behavior.</p>`
          }]
        }, {
          headTitle: "Refunds and Returns",
          content: [{
            title: "What happens if I return an item?",
            content: `<p>If you return an item to a merchant, the purchase balance will be refunded to the card originally used to make the purchase.</p>`
          }, {
            title: "I made a mistake when creating a card. Can I receive a refund?",
            content: `<p>No.</p>`
          }, {
            title: "What happens if I don't spend the full balance on a card?",
            content: `<p>Please don't do this.
</p>`
          }]
        }, {
          headTitle: "Restrictions",
          content: [{
            title: "Are there any restrictions when using a DaVinci Visa® Prepaid Card?",
            content: `<p>Some types of purchases are restricted. See the list of restricted merchant categories elsewhere in this FAQ. </p>`
          }, {
            title: "What types of purchases are restricted?",
            content: `<p>You cannot use DaVinci or the DaVinci Card for the following purchases: wires or money orders; direct marketing services; security brokers/dealers and associated activities; dating/escort services; massage services; betting, track, casino, or lotto; government-licensed online gambling, including casinos and online casinos (online gambling); horse/dog racing or other government licensed racing; non-sport internet gaming; internet gaming betting; cash disbursement, manual or automated; financial institution cash, automated or manual; and outbound telemarketing merchants and services. Additionally, DaVinci may restrict certain purchase types to prevent misuse of the services as set out in our terms and conditions.</p>`
          }, {
            title: "Are there any limits on purchases made with DaVinci Visa® Prepaid Cards?",
            content: `<p>Each virtual card can hold a maximum of $1000 at any time. Users may only spend $10,000 in any given 24 hour period across all of their virtual cards. Users may only spend $50,000 in any given calendar month across all of their virtual cards. Some merchants are restricted due to the types of business they conduct. Please see our list of restricted merchant categories elsewhere in this FAQ.</p>`
          }, {
            title: "Why are there limits on DaVinci Visa® Prepaid Cards?",
            content: `<p>These limits exist to comply with federal regulations and to meet our compliance obligations to our financial partners.</p>`
          }, {
            title: "Can I reload a card?",
            content: `<p>Yes.</p>`
          }, {
            title: "Can I transfer/withdraw a balance from my virtual card?",
            content: `<p>Once funds have been loaded to a card, those funds cannot be transferred or withdrawn.</p>`
          }, {
            title: "Can I use multiple cryptocurrencies to fund one card?",
            content: `<p>No</p>`
          }, {
            title: "Can I use cryptocurrency from multiple sources to fund one card?",
            content: `<p>No</p>`
          }, {
            title: "Can I use USD or other fiat currency to fund a card?",
            content: `<p>No</p>`
          }, {
            title: "Can I fund a Paypal account with a card?",
            content: `<p>No; this is restricted as part of our restricted purchases list which can be found elsewhere in this FAQ.</p>`
          }]
        }, {
          headTitle: "Troubleshooting",
          content: [{
            title: "Why did my purchase with a DaVinci Visa® Prepaid Card fail?",
            content: `<p>A failed purchase may be due to a few reasons: 1) You may not have enough balance on the virtual card to complete the purchase. 2) Some merchants are restricted due to the types of business they conduct. Please see our list of restricted merchant categories below in this FAQ. 3) Your purchase may cause you to exceed your daily or monthly spending limit. Please see details on spending limits below. 4) The merchant may not be based in the US. DaVinci does not support payments to non-US based merchants. If you believe your purchase should have been completed, please reach out to support@paywithDaVinci.com.</p>`
          }, {
            title: "I made a mistake when creating a card, can I receive a refund?",
            content: `<p>Yes. Click "Delete" under the card to delete the card and receive a credit to your DaVinci account for the unspent amount. Credit can be used towards the purchase of additional cards
</p>`
          }, ]
        }, {
          headTitle: "Other",
          content: [{
            title: "Why have I been directed to this FAQ?",
            content: `<p>Our banking partners require that all public statements regarding the DaVinci Visa® Prepaid Card be approved before release. This FAQ serves as a list of preapproved language that answers common questions. If we've directed you here, we hope it answers your question. If not, you can be sure we'll be updating this page with additional information shortly.</p>`
          }, ]
        }]
      }
    },
    created() {
      this.$emit('imageBg', {})
    },
    methods: {}
  }
</script>

<style lang="scss">
  .faq_container {
    .head_title {
      margin-top: 3rem;
    }

    .card {
      border: none;
      border-bottom: 1px solid #ccc;
      // border-top: 1px solid #ccc;
      border-radius: 0;

      .card-header {
        border: 0;
        background-color: #fff;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;

        .icon_rotate {
          transform: rotate(90deg);
          transition: .3s;
        }

        .collapsed {
          .icon_rotate {
            transform: rotate(-90deg);
          }
        }
      }

      .card-body {
        padding: 0;
      }
    }
  }
</style>
